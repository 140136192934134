/**********************************************************************************************************************
 * Utility styles
 **********************************************************************************************************************/
.p0 {
  padding: 0px !important; }

.pt0 {
  padding-top: 0px !important; }

.pr0 {
  padding-right: 0px !important; }

.pb0 {
  padding-bottom: 0px !important; }

.pl0 {
  padding-left: 0px !important; }

.m0 {
  margin: 0px !important; }

.mt0 {
  margin-top: 0px !important; }

.mr0 {
  margin-right: 0px !important; }

.mb0 {
  margin-bottom: 0px !important; }

.ml0 {
  margin-left: 0px !important; }

.pxs {
  padding: 5px !important; }

.ps {
  padding: 10px !important; }

.pm {
  padding: 15px !important; }

.pl {
  padding: 20px !important; }

.pxl {
  padding: 25px !important; }

.ptxs {
  padding-top: 5px !important; }

.pts {
  padding-top: 10px !important; }

.ptm {
  padding-top: 15px !important; }

.ptl {
  padding-top: 20px !important; }

.ptxl {
  padding-top: 25px !important; }

.prxs {
  padding-right: 5px !important; }

.prs {
  padding-right: 10px !important; }

.prm {
  padding-right: 15px !important; }

.prl {
  padding-right: 20px !important; }

.prxl {
  padding-right: 25px !important; }

.pbxs {
  padding-bottom: 5px !important; }

.pbs {
  padding-bottom: 10px !important; }

.pbm {
  padding-bottom: 15px !important; }

.pbl {
  padding-bottom: 20px !important; }

.pbxl {
  padding-bottom: 25px !important; }

.plxs {
  padding-left: 5px !important; }

.pls {
  padding-left: 10px !important; }

.plm {
  padding-left: 15px !important; }

.pll {
  padding-left: 20px !important; }

.plxl {
  padding-left: 25px !important; }

.mxs {
  margin: 5px !important; }

.ms {
  margin: 10px !important; }

.mm {
  margin: 15px !important; }

.ml {
  margin: 20px !important; }

.mxl {
  margin: 25px !important; }

.mtxs {
  margin-top: 5px !important; }

.mts {
  margin-top: 10px !important; }

.mtm {
  margin-top: 15px !important; }

.mtl {
  margin-top: 20px !important; }

.mtxl {
  margin-top: 25px !important; }

.mrxs {
  margin-right: 5px !important; }

.mrs {
  margin-right: 10px !important; }

.mrm {
  margin-right: 15px !important; }

.mrl {
  margin-right: 20px !important; }

.mrxl {
  margin-right: 25px !important; }

.mbxs {
  margin-bottom: 5px !important; }

.mbs {
  margin-bottom: 10px !important; }

.mbm {
  margin-bottom: 15px !important; }

.mbl {
  margin-bottom: 20px !important; }

.mbxl {
  margin-bottom: 25px !important; }

.mlxs {
  margin-left: 5px !important; }

.mls {
  margin-left: 10px !important; }

.mlm {
  margin-left: 15px !important; }

.mll {
  margin-left: 20px !important; }

.mlxl {
  margin-left: 25px !important; }

/**********************************************************************************************************************
 * Typography styles
 **********************************************************************************************************************/
.text-bold {
  font-weight: bold; }

/**********************************************************************************************************************
 * Bootstrap form styles
 **********************************************************************************************************************/
.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  background-color: #eef1f5 !important;
  opacity: 1; }

.form-control.clear-background {
  border: none;
  background: transparent !important;
  color: #22304b !important;
  padding: 0; }

.input-icon.right {
  top: -36px; }

/**********************************************************************************************************************
 * Button styles
 **********************************************************************************************************************/
/* override all buttons */
a.btn, button.btn {
  box-shadow: 1px 1px 3px #464646 !important; }

/* override purple button */
.btn.purple:not(.btn-outline) {
  background-color: #344fb2;
  border-color: #344fb2; }

/* override purple button hover style */
.btn.purple:not(.btn-outline).active,
.btn.purple:not(.btn-outline):active,
.btn.purple:not(.btn-outline):hover,
.open > .btn.purple:not(.btn-outline).dropdown-toggle {
  background-color: #373e6e;
  border-color: #373e6e; }

/* override green button */
.btn.green:not(.btn-outline) {
  background-color: #0091F4;
  border-color: #0091F4; }

/* override green button hover style */
.btn.green:not(.btn-outline).active,
.btn.green:not(.btn-outline):active,
.btn.green:not(.btn-outline):hover,
.open > .btn.green:not(.btn-outline).dropdown-toggle {
  background-color: #009db4;
  border-color: #009db4; }

/* font colors */
.font-darkblue {
  color: #22304b !important; }

/**********************************************************************************************************************
 * Image styles
 **********************************************************************************************************************/
.image {
  opacity: 0;
  transition: opacity 0.3s; }

.image-loaded {
  opacity: 1; }

.img50_50 {
  width: 50px;
  height: 50px; }

/**********************************************************************************************************************
 * react-toastify styles
 **********************************************************************************************************************/
.toastify {
  z-index: 10001; }

/* generic toaster error style */
.toast-success, .toast-error, .toast-warning, .toast-info {
  box-shadow: 2px 2px 5px #464646 !important;
  color: #22304b; }

/* toaster close icon style */
.toast-success > .toastify__close,
.toast-error > .toastify__close,
.toast-warning > .toastify__close,
.toast-info > .toastify__close {
  color: #fff; }

/* success toaster */
.toast-success {
  background: #07BC0C;
  color: #fff; }

/* error toaster */
.toast-error {
  background: #E43A45;
  color: #fff; }

.toast-error > .toastify__close {
  color: #fff; }

/* warning toaster */
.toast-warning {
  background: #F1C40F; }

/* info toaster */
.toast-info {
  background: #0091F4; }

/**********************************************************************************************************************
 * sweetalert 2
 **********************************************************************************************************************/
.swal2-modal {
  border-radius: 0px; }

.swal2-modal button {
  border-radius: 0px !important; }

/**********************************************************************************************************************
 * loading indicator
 **********************************************************************************************************************/
.spinner {
  position: fixed;
  z-index: 999;
  height: 4em;
  width: 4em;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

/* Transparent Overlay */
.spinnerWrapper {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999; }

/**********************************************************************************************************************
 * material ui overwrite
 **********************************************************************************************************************/
.rounded-circle {
  border-radius: 50% !important;
  border: 1px solid #22304b; }

img.img120_120 {
  width: 120px;
  height: 120px; }

.logo-sidebar {
  height: 45px;
  width: auto; }

.login .content {
  background-color: #fff; }

.login {
  background: url(https://cdn.getzave.com/assets/zavehub/bg-zavehub-login.png);
  background-size: cover; }

.incorporation-login {
  background-color: #f5f5f5;
  width: 100%;
  height: 100%; }

.page-login .body-inner:before {
  background-color: #0091F4; }

.disabled-textfield input:disabled {
  color: rgba(0, 0, 0, 0.87) !important; }

.action-button span {
  color: #fff !important; }

/*======================================================================================================================
 * React Table (https://react-table.js.org)
 *====================================================================================================================*/
div.-sort-asc::after {
  content: "\25BC";
  position: absolute;
  right: 6px;
  top: 5px; }

div.-sort-desc::after {
  content: "\25B2";
  position: absolute;
  right: 6px;
  top: 5px; }

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc,
.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.ReactTable a.table-link,
.ReactTable a.table-link span {
  cursor: pointer;
  color: #0091F4 !important; }

.ReactTable a.table-link:hover,
.ReactTable a.table-link:focus {
  text-decoration: underline; }

.ReactTable .rt-tbody .rt-tr-group {
  line-height: 30px; }

.ReactTable .rt-table {
  flex: auto 1 !important; }

/*======================================================================================================================
 * React Tether
 *====================================================================================================================*/
.tether-element {
  z-index: 10000;
  box-shadow: 0px 0px 10px #808080; }

.tether-element-overlay {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999; }

/*======================================================================================================================
 * Bank transaction verification chat dialog
 *====================================================================================================================*/
.dialog-body {
  top: 0px !important; }

.dialog-container-chat {
  max-width: 2000px !important;
  width: 80% !important; }

.verification-card-actions {
  margin-top: 15px; }

.verification-card td {
  padding-top: 10px; }

.verification-card .verification-card-header {
  padding-right: 10px;
  font-weight: 800;
  min-width: 120px; }

.verification-card .verification-card-text {
  padding-top: 2px; }

/*======================================================================================================================
 * emoji picker
 *====================================================================================================================*/
.emoji-mart-preview-emoticons {
  display: none; }

@media only screen and (max-width: 480px) {
  .emoji-mart {
    width: 100% !important; } }

/*======================================================================================================================
 * Responsive styling
 *====================================================================================================================*/
/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
  .tether-element {
    width: 50% !important; } }

/* Medium Devices, Desktops */
/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .tether-element {
    width: 70% !important; }
  .dialog-container {
    width: 90% !important; }
  .dialog-container-chat {
    width: 95% !important; } }

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .container-fluid {
    padding-left: 0px !important;
    padding-right: 0px !important; }
  .tether-element {
    width: 90% !important; }
  .dialog-container-chat {
    width: 95% !important; } }

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {
  .container-fluid {
    padding-left: 0px !important;
    padding-right: 0px !important; }
  .tether-element {
    width: 90% !important; }
  .dialog-container-chat {
    width: 95% !important; } }

/*======================================================================================================================
 * Bottom controls styling
 *====================================================================================================================*/
#btm-controls {
  border-top: 2px solid #000;
  background-color: #fff;
  border-color: #ddd;
  color: #333;
  width: 100%;
  height: 55px;
  position: fixed;
  bottom: 0;
  left: 0;
  margin: auto;
  padding: 10px;
  text-align: center;
  z-index: 99999; }

/*======================================================================================================================
 * White overlay container styles
 *====================================================================================================================*/
.overlay-container {
  background-color: #0091F4;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  filter: alpha(opacity=80);
  /* For IE8 and earlier */
  text-align: center; }

.overlay-container .overlay-container-content {
  position: absolute;
  top: 45%;
  left: 45%; }

/*======================================================================================================================
 * Side Nav
 *====================================================================================================================*/
.beta-badge {
  border: 1px solid #0091f4;
  border-radius: 3px;
  color: #0091f4;
  margin: 3px;
  font-size: 0.7em;
  vertical-align: top;
  line-height: 2; }

.beta-badge-float {
  background-color: #fff;
  position: absolute;
  z-index: 1;
  right: -7px;
  top: -18px;
  line-height: 1.2;
  padding: 1px; }

.beta-badge-parent {
  position: relative; }

/*======================================================================================================================
 * react-phone-number-input styles
 *====================================================================================================================*/
.react-phone-number-input__icon {
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 8px; }

.react-phone-number-input__input.react-phone-number-input__phone {
  font-size: 16px; }

.react-phone-number-input__input--invalid {
  border-color: #F44336 !important;
  border-width: 2px; }

.react-phone-number-input__error {
  bottom: 15px;
  font-size: 12px;
  line-height: 12px;
  color: #F44336; }

.react-phone-number-input__input {
  border-bottom-color: #e0e0e0; }

/*======================================================================================================================
 * Incorporation form styling
 *====================================================================================================================*/
.reset-form-link {
  color: #868e96 !important;
  cursor: pointer; }

.reset-form-link:focus,
.reset-form-link:hover {
  color: #0091F4 !important;
  text-decoration: underline; }

@media only screen and (max-width: 575px) {
  .section-header-text {
    margin-top: 4px; } }

.divider {
  margin-top: 10px !important;
  margin-bottom: 10px !important; }

.divider-2 {
  height: 3px !important; }

.summary-header {
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 8px; }

.summary-wrapper,
.overview-wrapper,
.overview-content,
.overview-amount {
  padding-bottom: 7px; }

.summary-amount,
.summary-frequency {
  padding-top: 33px; }

.summary-content-header,
.summary-amount-header,
.summary-frequency-header,
.overview-content-header,
.overview-amount-header {
  font-size: 18px;
  font-weight: 100; }

@media only screen and (max-width: 768px) {
  .summary-content-header,
  .summary-amount-header,
  .summary-frequency-header,
  .summary-card-header {
    display: none; } }

#incorp-search-results-logo {
  margin-top: 90px; }

#incorp-search-results-input .step2 {
  margin-top: 69px !important; }

@media only screen and (min-width: 1980px) {
  #incorp-search-results-input .step2 {
    margin-top: 90px !important; } }

@media only screen and (min-width: 3450px) {
  #incorp-search-results-input .step2 {
    margin-top: 100px !important; } }

#incorp-search-results-input-icon {
  position: absolute;
  right: 18px;
  bottom: 15px; }

#incorp-search-results-input-icon.error {
  bottom: 40px; }

/*======================================================================================================================
 * CommonStepNavigator Styles
 *====================================================================================================================*/
.common-stepnav {
  cursor: pointer;
  border-radius: 1em; }

.common-stepnav:hover {
  background-color: whitesmoke; }

/*======================================================================================================================
 * Sweet Alert Prompt Styles
 *====================================================================================================================*/
.swal2-container {
  z-index: 5000 !important; }

/*======================================================================================================================
 * Stripe form fields styling
 *====================================================================================================================*/
.StripeElement {
  background-color: white;
  height: 40px;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease; }

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df; }

.StripeElement--invalid {
  border-color: #fa755a; }

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important; }

/*======================================================================================================================
 * Task Concierge Styles
 *====================================================================================================================*/
.dialog-container-concierge-tooltip {
  max-width: 2500px !important;
  width: 70% !important; }

.taskconcierge-tooltip-list {
  list-style: none; }

.taskconcierge-tooltip-list ul {
  color: 'white'; }

.taskconcierge-tooltip-list li::before {
  content: '\2013';
  width: 1.5em;
  display: inline-block;
  color: #0091F4;
  margin-left: -20px; }

.suggestions-list {
  display: inline;
  list-style: none; }

.suggestions-list li {
  display: inline; }

.suggestions-list li:after {
  content: ",\00a0"; }

.suggestions-list li:last-child:after {
  content: ""; }

/* AutoComplete field - popover menu item style */
span[role="menuitem"] div div {
  white-space: pre-wrap;
  line-height: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 13px; }
